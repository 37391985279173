<template>
  <el-dialog 
    title="订单状态"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form v-loading="loading">
      <el-form-item prop="status" label="当前订单状态：">
        <div> 交易成功 </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'status',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.getStatus()
      }
    }
  },
  methods: {
    async getStatus(){
      this.loading = true
      setTimeout(() => {

        this.loading = false
      }, 300)
    },
    handleClose(){
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>

</style>